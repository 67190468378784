import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import MegaDropDown_heat_pumps from '../../../assets/images/website-frontend/product-listing/menu/Heat pumps.webp';
// import MegaDropDown_hot_tub from '../../../assets/images/website-frontend/product-listing/menu/Hot tub.webp';
import CallIcon from '../../../assets/images/call-icon.png';
import { ADMIN_URL, CONFIG, IMAGE_PATH } from '../../../config/constant';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = (props) => {

    /** Logo start */
    const [offset, setOffset] = useState(0);
    const [logoImage, setLogoImage] = useState("");
    const setLogo = () => {
        if (offset < 50) {
            console.log("below");
            $('.navbar').addClass('is-transparent');
            setLogoImage(headerData.logo.white_logo);
        } else {
            console.log("above");
            $('.navbar').removeClass('is-transparent');
            setLogoImage(headerData.logo.colourful_logo);
        }
    }
    /** Logo end */

    const [headerData, setHeaderData] = useState({
        renolit: [],
        service: [],
        project: [],
        product: [],
        logo: {},
        renolitHeading: "",
        renolitRedirect: "",
        serviceHeading: "",
        serviceRedirect: "",
        projectHeading: "",
        projectRedirect: "",
        productHeading: "",
        productRedirect: "",
        aboutHeading: "",
        aboutRedirect: "",
        newsAndUpdateHeading: "",
        newsAndUpdateRedirect: "",
        contactHeading: "",
        contactRedirect: "",
    });

    const requestOptionsHeader = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    };

    const loadHeaderData = async () => {
        const result = await fetch(ADMIN_URL + "api/website-frontend/setting", requestOptionsHeader);
        result.json().then((content) => {
            setHeaderData(content.success.header);
            setLogoImage(content.success.header.logo.white_logo);
        });
    }

    /** Mega Dropdown start */
    const [megaDdropdownOpenRenolit, setMegaDdropdownOpenRenolit] = useState(false);
    const [megaDdropdownOpenService, setMegaDdropdownOpenService] = useState(false);
    const [megaDdropdownOpenProject, setMegaDdropdownOpenProject] = useState(false);
    const [megaDdropdownOpenProduct, setMegaDdropdownOpenProduct] = useState(false);

    const openMegaDropdownRenolit = () => {
        setMegaDdropdownOpenRenolit(!megaDdropdownOpenRenolit);
        setMegaDdropdownOpenService(false);
        setMegaDdropdownOpenProject(false);
        setMegaDdropdownOpenProduct(false);
        if (!$('.navbar-item.has-dropdown').hasClass('dropdown-active') || megaDdropdownOpenService || megaDdropdownOpenProject || megaDdropdownOpenProduct) {
            $('.navbar').removeClass('is-transparent');
        } else {
            $('.navbar').addClass('is-transparent');
        }
    }
    const openMegaDropdownService = () => {
        setMegaDdropdownOpenRenolit(false);
        setMegaDdropdownOpenService(!megaDdropdownOpenService);
        setMegaDdropdownOpenProject(false);
        setMegaDdropdownOpenProduct(false);
        if (!$('.navbar-item.has-dropdown').hasClass('dropdown-active') || megaDdropdownOpenRenolit || megaDdropdownOpenProject || megaDdropdownOpenProduct) {
            $('.navbar').removeClass('is-transparent');
        } else {
            $('.navbar').addClass('is-transparent');
        }
    }
    const openMegaDropdownProject = () => {
        setMegaDdropdownOpenRenolit(false);
        setMegaDdropdownOpenService(false);
        setMegaDdropdownOpenProject(!megaDdropdownOpenProject);
        setMegaDdropdownOpenProduct(false);
        if (!$('.navbar-item.has-dropdown').hasClass('dropdown-active') || megaDdropdownOpenRenolit || megaDdropdownOpenService || megaDdropdownOpenProduct) {
            $('.navbar').removeClass('is-transparent');
        } else {
            $('.navbar').addClass('is-transparent');
        }
    }
    const openMegaDropdownProduct = () => {
        setMegaDdropdownOpenRenolit(false);
        setMegaDdropdownOpenService(false);
        setMegaDdropdownOpenProject(false);
        setMegaDdropdownOpenProduct(!megaDdropdownOpenProduct);
        if (!$('.navbar-item.has-dropdown').hasClass('dropdown-active') || megaDdropdownOpenRenolit || megaDdropdownOpenService || megaDdropdownOpenProject) {
            $('.navbar').removeClass('is-transparent');
        } else {
            $('.navbar').addClass('is-transparent');
        }
    }
    const closeMegaDropdown = () => {
        setMegaDdropdownOpenRenolit(false);
        setMegaDdropdownOpenService(false);
        setMegaDdropdownOpenProject(false);
        setMegaDdropdownOpenProduct(false);
    }
    /** Mega Dropdown end */

    const tab_accordion = () => {
        $('.tab-accordion .accordion-section-title').click(function (e) {
            var currentAttrValue = $(this).attr('data-acc');
            
            if ($(e.target).is('.active')) {
                $(this).removeClass('active');
                $(this).siblings().slideUp(300).removeClass('open');
            } else {
                $(this).parent().siblings().children('.accordion-section-title').removeClass('active');
                $(this).parent().siblings().children('.accordion-section-content').slideUp(300).removeClass('open');

                $(this).addClass('active');
                $('#' + currentAttrValue).slideDown(300).addClass('open');
            }
        });
    }

    // Humberger Menu
    const [activeBurgerMenu, setActiveBurgerMenu] = useState(false);
    const openBurger = () => {
        console.log('click');
        setActiveBurgerMenu(!activeBurgerMenu);
    }

    useEffect(() => {
        // window.scrollTo(0, 0)
        loadHeaderData();
        setLogo();
        setOffset(props.offset);
        window.addEventListener('scroll', function () {
            setOffset(window.pageYOffset)
        });
        tab_accordion();
    }, [])

    return (
        <>
            {/*  Navigation starts */}
            <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
                {/*  [class.is-justify-content-center]="!removeHeader" */}
                <div className="container extrahd">
                    <div className="navbar-brand">
                        <Link to="/" onClick={closeMegaDropdown} className="navbar-item">
                            <LazyLoadImage className="logo-color" src={props.logo} alt={headerData.logo.logo_alt_title} title={headerData.logo.logo_alt_title} width="100%" height="100%" />
                        </Link>
                    </div>

                    {/*  [class.is-active]="activeMenu" *ngIf="removeHeader" */}
                    <div id="navbarBasicExample" className="navbar-menu">
                        {/*  <ng-container *ngFor="let nav of app.navigation"> */}
                        {/*  onClick={closeBurger} */}
                        {/*  <Link to="/" className="navbar-item transparent-nav pr-5 pl-5 has-text-primary" *ngIf="nav.type == 'basic'" >{{nav.text}}</Link> */}
                        {/*  [class.is-hoverable]="ishoverable" onClick={closeDropdown} */}
                        {/*  <div className="navbar-item has-dropdown is-hoverable mob-nav-tab-dd-sec" *ngIf="nav.type == 'dropdown'">
                            <Link to="/" className="navbar-link transparent-nav has-text-primary mob-nav-tab-dd-title" data-tab="">{{nav.text}}</Link>
                            <div className="navbar-dropdown mob-nav-tab-dd-content" id="{{nav.text}}">
                                <ng-container *ngFor="let subNav of nav.dropdown">
                                    onClick={closeBurger}
                                    <Link to="/" className="navbar-item">{{subNav.text}}</Link>
                                </ng-container>
                            </div>
                        </div> */}
                        {/*  [class.is-hoverable]="ishoverable" (mouseenter)="closeDropdown()" (mouseenter)="removeIsTransparent()" (mouseleave)="addIsTransparent()" */}
                        {/*  *ngIf="nav.type == 'megaDropdown'" */}
                        <div className={"navbar-item has-dropdown is-hoverable mob-nav-tab-dd-sec " + (megaDdropdownOpenProject ? 'dropdown-active' : '')} onClick={openMegaDropdownProject}>
                            <p className="navbar-link transparent-nav mob-nav-tab-dd-title is-para para-medium">{headerData.projectHeading}</p>
                            {/*  Dropdown menu */}
                            <section className="mega-dropdown-section section navbar-dropdown mob-nav-tab-dd-content" id="Projects">
                                <span className="close-btn" onClick={openMegaDropdownService}>+</span>
                                <div className="container">
                                    <div className="columns is-vcentered is full">
                                        <div className="content-column column">
                                            {headerData.project.map((projectRow, i) => (
                                                <Link className="heading-02 margin-t-4 margin-b-4" to={headerData.projectRedirect+"/" + projectRow.name.replace(/\s+/g, '-').toLowerCase()} >{projectRow.name}</Link>
                                                // <Link className="heading-02 margin-t-4 margin-b-4" to="/project/commercial" >Commercial</Link>
                                                // <Link className="heading-02 margin-t-4 margin-b-4" to="/project/competition" >Competition</Link>
                                                // <Link className="heading-02 margin-t-4 margin-b-4" to="/project/personal" >Personal</Link>
                                                // <Link className="heading-02 margin-t-4 margin-b-4" to="/project/hotel-and-resorts" >Hotel And Resorts</Link>
                                                // <Link className="heading-02 margin-t-4 margin-b-4" to="/project/water-parks" >Water Parks</Link>
                                            ))}
                                        </div>
                                        {/*  *ngIf="desktopView" */}
                                        <div className="img-column column dropdown">
                                            {/*  {{displayImage}} */}
                                            <LazyLoadImage className="image is-square p-0" src={MegaDropDown_heat_pumps} alt="" width="100%" height="100%" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*  Dropdown menu ends */}
                        </div>
                        <div className={"navbar-item has-dropdown is-hoverable mob-nav-tab-dd-sec " + (megaDdropdownOpenProduct ? 'dropdown-active' : '')} onClick={openMegaDropdownProduct} >
                            <p className="navbar-link transparent-nav mob-nav-tab-dd-title is-para para-medium" >{headerData.productHeading}</p>
                            {/*  Dropdown menu */}
                            <section className="mega-dropdown-section section navbar-dropdown mob-nav-tab-dd-content" id="Products">
                                <span className="close-btn" onClick={openMegaDropdownService}>+</span>
                                <div className="container">
                                    <div className="columns is-vcentered is full">
                                        <div className="content-column column" >
                                            {headerData.product.map((productRow, i) => (
                                                <Link className="heading-02 margin-t-4 margin-b-4" to={headerData.productRedirect+"/" + productRow.name.replace(/\s+/g, '-').toLowerCase()} key={i}>{productRow.name}</Link>
                                            ))}
                                        </div>
                                        {/*  *ngIf="desktopView" */}
                                        <div className="img-column column dropdown">
                                            {/*  {{displayImage}} */}
                                            {headerData.product.map((productRow, i) => (
                                                <LazyLoadImage className="image is-square p-0" src={IMAGE_PATH + productRow.navigation_image} alt={productRow.navigation_image_alt_title} width="100%" height="100%" />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*  Dropdown menu ends */}
                        </div>
                        <div className={"navbar-item has-dropdown is-hoverable mob-nav-tab-dd-sec " + (megaDdropdownOpenService ? 'dropdown-active' : '')} onClick={openMegaDropdownService}>
                            <p className="navbar-link transparent-nav mob-nav-tab-dd-title is-para para-medium">{headerData.serviceHeading}</p>
                            {/*  Dropdown menu */}
                            <section className="mega-dropdown-section section navbar-dropdown mob-nav-tab-dd-content" id="Services">
                                <span className="close-btn" onClick={openMegaDropdownService}>+</span>
                                <div className="container">
                                    <div className="columns is-vcentered is full">
                                        <div className="content-column column">
                                            {headerData.service.map((serviceRow, i) => (
                                                <Link className="heading-02 margin-t-4 margin-b-4" to={headerData.serviceRedirect+"/" + serviceRow.listing_name.replace(/\s+/g, '-').toLowerCase()} key={i}>{serviceRow.listing_name}</Link>
                                            ))}
                                            {/* <Link className="heading-02 margin-t-4 margin-b-4" to="/service/reinforced-cement-concrete">Reinforced Cement Concrete</Link>
                                            <Link className="heading-02 margin-t-4 margin-b-4" to="/service/fibre-reinforced-plastic-tub">Fibre-reinforced Plastic Tub</Link>
                                            <Link className="heading-02 margin-t-4 margin-b-4" to="/service/pool-renovation">Pool Renovation</Link>
                                            <Link className="heading-02 margin-t-4 margin-b-4" to="/service/hot-tubs">Hot Tubs</Link> */}
                                        </div>
                                        {/*  *ngIf="desktopView" */}
                                        <div className="img-column column dropdown">
                                            {/*  {{displayImage}} */}
                                            {headerData.service.map((serviceRow, i) => (
                                                <LazyLoadImage className="image is-square p-0" src={IMAGE_PATH + serviceRow.navigation_image} alt={serviceRow.navigation_image_alt_title} key={i} width="100%" height="100%" />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*  Dropdown menu ends */}
                        </div>
                        <div className={"navbar-item has-dropdown is-hoverable mob-nav-tab-dd-sec " + (megaDdropdownOpenRenolit ? 'dropdown-active' : '')} onClick={openMegaDropdownRenolit} style={{ display: 'none' }}>
                            <p className="navbar-link transparent-nav mob-nav-tab-dd-title is-para para-medium">{headerData.renolitHeading}</p>
                            {/*  Dropdown menu */}
                            <section className="mega-dropdown-section section navbar-dropdown mob-nav-tab-dd-content" id="Renolit">
                                <span className="close-btn" onClick={openMegaDropdownService}>+</span>
                                <div className="container">
                                    <div className="columns is-vcentered is full">
                                        <div className="content-column column">
                                            {headerData.renolit.map((renolitRow, i) => (
                                                <Link to={headerData.renolitRedirect+"/" + renolitRow.listing_name.replace(/\s+/g, '-').toLowerCase()} className="heading-02 margin-t-4 margin-b-4" key={i}>{renolitRow.listing_name}</Link>
                                            ))}
                                            {/* <Link to="/renolit/the-touch" className="heading-02 margin-t-4 margin-b-4">The Touch</Link>
                                            <Link to="/renolit/the-xtreme" className="heading-02 margin-t-4 margin-b-4">The Xtreme</Link>
                                            <Link to="/renolit/the-ceramics" className="heading-02 margin-t-4 margin-b-4">The Ceramics</Link> */}
                                        </div>
                                        {/*  *ngIf="desktopView" */}
                                        <div className="img-column column dropdown">
                                            {/*  {{displayImage}} */}
                                            {headerData.renolit.map((renolitRow, i) => (
                                                <LazyLoadImage className="image is-square p-0" src={IMAGE_PATH + renolitRow.navigation_image} alt={renolitRow.navigation_image_alt_title} key={i} width="100%" height="100%" />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*  Dropdown menu ends */}
                        </div>
                        {/*  </ng-container> */}
                        <Link to={headerData.whySwimwellRedirect} onClick={closeMegaDropdown} className="navbar-item transparent-nav is-para para-medium">{headerData.whySwimwellHeading}</Link>
                        <Link to={headerData.aboutRedirect} onClick={closeMegaDropdown} className="navbar-item transparent-nav is-para para-medium">{headerData.aboutHeading}</Link>
                        {/* <Link to={headerData.newsAndUpdateRedirect} onClick={closeMegaDropdown} className="navbar-item transparent-nav is-para para-medium">{headerData.newsAndUpdateHeading}</Link> */}
                        <Link to={headerData.contactRedirect} onClick={closeMegaDropdown} className="navbar-item transparent-nav is-para para-medium">{headerData.contactHeading}</Link>
                    </div>

                    {/*  *ngIf="removeHeader" */}
                    <div className="navbar-end second-nav" style={{ display: 'none' }}>
                        {/*  onClick={openSearchBar} */}
                        {/*  <Link to="/" className="navbar-item">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8598 15.184L11.9716 11.2958C13.0146 10.0967 13.6461 8.53215 13.6461 6.82217C13.6461 3.05809 10.5844 0 6.82388 0C3.0598 0 0.00170898 3.06164 0.00170898 6.82217C0.00170898 10.5827 3.06335 13.6443 6.82388 13.6443C8.53386 13.6443 10.0984 13.0129 11.2975 11.9698L15.1857 15.8581C15.278 15.9503 15.4022 16 15.5228 16C15.6434 16 15.7676 15.9539 15.8598 15.8581C16.0443 15.6736 16.0443 15.3685 15.8598 15.184ZM0.956033 6.82217C0.956033 3.5867 3.58841 0.957871 6.82033 0.957871C10.0558 0.957871 12.6846 3.59024 12.6846 6.82217C12.6846 10.0541 10.0558 12.69 6.82033 12.69C3.58841 12.69 0.956033 10.0576 0.956033 6.82217Z" fill="#023915"/>
                        </svg>                        
                    </Link> */}
                        {/* <Link to="/" className="navbar-item transparent-nav" onClick={redirectOnCart}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9959 13.8307L14.8513 3.51411C14.8268 3.28507 14.5856 3.11166 14.3035 3.11166H11.9489C11.9162 1.39059 10.1584 0 8 0C5.84159 0 4.0838 1.39059 4.0511 3.11166H1.69647C1.41032 3.11166 1.17322 3.28507 1.1487 3.51411L0.00408798 13.8307C0.00408798 13.8438 0 13.8569 0 13.8699C0 15.0446 1.34492 16 3.00051 16H12.9995C14.6551 16 16 15.0446 16 13.8699C16 13.8569 16 13.8438 15.9959 13.8307ZM8 0.883436C9.54931 0.883436 10.8125 1.87812 10.8452 3.11166H5.15483C5.18753 1.87812 6.45069 0.883436 8 0.883436ZM12.9995 15.1166H3.00051C1.96219 15.1166 1.12008 14.5669 1.10373 13.8896L2.19928 3.99836H4.04701V5.33988C4.04701 5.58528 4.29228 5.78159 4.59888 5.78159C4.90547 5.78159 5.15074 5.58528 5.15074 5.33988V3.99836H10.8452V5.33988C10.8452 5.58528 11.0904 5.78159 11.397 5.78159C11.7036 5.78159 11.9489 5.58528 11.9489 5.33988V3.99836H13.7966L14.8963 13.8896C14.8799 14.5669 14.0337 15.1166 12.9995 15.1166Z" fill="#023915"/>
                        </svg>                        
                        <span className="icon-badge" *ngIf='this.cartLength!=null'>{{this.cartLength}}</span>
                    </Link> */}
                        {/* <Link to="/" className="navbar-item transparent-nav" onClick={loginCheck}rofile")' *ngIf='!isLogged'> */}
                        {/* <Link to="/" className="navbar-item transparent-nav" onClick={loginCheckprofile}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.94206 8.62125C7.95863 8.62125 7.9752 8.62125 7.99508 8.62125C8.0017 8.62125 8.00833 8.62125 8.01496 8.62125C8.0249 8.62125 8.03815 8.62125 8.04809 8.62125C9.01889 8.60468 9.80415 8.26341 10.384 7.61069C11.6596 6.17271 11.4475 3.7076 11.4244 3.47235C11.3415 1.70636 10.5066 0.861462 9.8174 0.467178C9.30383 0.172292 8.70412 0.0132533 8.03484 0H8.01164C8.00833 0 8.0017 0 7.99839 0H7.97851C7.61073 0 6.88843 0.0596397 6.19595 0.453924C5.50015 0.848209 4.65194 1.6931 4.56911 3.47235C4.54591 3.7076 4.33386 6.17271 5.60949 7.61069C6.18601 8.26341 6.97126 8.60468 7.94206 8.62125ZM5.45376 3.55519C5.45376 3.54525 5.45708 3.53531 5.45708 3.52868C5.56642 1.15303 7.25289 0.897909 7.9752 0.897909H7.98845C7.99508 0.897909 8.00502 0.897909 8.01496 0.897909C8.90955 0.917788 10.4304 1.28225 10.5331 3.52868C10.5331 3.53862 10.5331 3.54856 10.5364 3.55519C10.5397 3.57838 10.7716 5.83144 9.718 7.0176C9.30052 7.48809 8.74389 7.72003 8.01164 7.72665C8.00502 7.72665 8.0017 7.72665 7.99508 7.72665C7.98845 7.72665 7.98514 7.72665 7.97851 7.72665C7.24958 7.72003 6.68963 7.48809 6.27546 7.0176C5.22514 5.83806 5.45045 3.57507 5.45376 3.55519Z" fill="#023915"/>
                            <path d="M14.804 12.7099C14.804 12.7066 14.804 12.7033 14.804 12.6999C14.804 12.6734 14.8007 12.6469 14.8007 12.6171C14.7808 11.9611 14.7377 10.427 13.2998 9.93663C13.2898 9.93332 13.2766 9.93 13.2666 9.92669C11.7723 9.54566 10.5298 8.68419 10.5166 8.67425C10.3145 8.53178 10.0361 8.58148 9.89367 8.78359C9.7512 8.98571 9.80089 9.26402 10.003 9.4065C10.0593 9.44626 11.378 10.364 13.0281 10.7882C13.8001 11.0632 13.8862 11.8882 13.9094 12.6436C13.9094 12.6734 13.9094 12.6999 13.9127 12.7264C13.916 13.0246 13.8962 13.4852 13.8431 13.7503C13.3064 14.0551 11.2024 15.1087 8.00176 15.1087C4.81436 15.1087 2.69715 14.0518 2.15708 13.747C2.10406 13.4819 2.08087 13.0213 2.0875 12.7231C2.0875 12.6966 2.09081 12.6701 2.09081 12.6403C2.114 11.8849 2.20015 11.0598 2.97215 10.7848C4.62218 10.3607 5.94088 9.43963 5.99721 9.40318C6.19932 9.26071 6.24902 8.98239 6.10655 8.78028C5.96408 8.57817 5.68576 8.52847 5.48364 8.67094C5.47039 8.68088 4.23452 9.54234 2.73359 9.92338C2.72034 9.92669 2.7104 9.93 2.70046 9.93332C1.26248 10.427 1.21941 11.9611 1.19953 12.6138C1.19953 12.6436 1.19953 12.6701 1.19621 12.6966C1.19621 12.6999 1.19621 12.7033 1.19621 12.7066C1.1929 12.8789 1.18959 13.7635 1.36519 14.2075C1.39833 14.2936 1.45797 14.3665 1.53749 14.4162C1.63689 14.4825 4.01916 16 8.00508 16C11.991 16 14.3733 14.4792 14.4727 14.4162C14.5489 14.3665 14.6118 14.2936 14.645 14.2075C14.8106 13.7668 14.8073 12.8822 14.804 12.7099Z" fill="#023915"/>
                        </svg>                        
                    </Link> */}
                        {/*  */}
                        {/* <div className="navbar-item has-dropdown is-hoverable" *ngIf='isLogged' [class.is-hoverable]="isClickMobile" [class.is-active]="isClickMobile" onClick={closeMobileDropdown} (mouseleave)="closeMobileDropdown()"> */}
                        {/* <div className="navbar-item has-dropdown is-hoverable" onClick={closeMobileDropdown}>
                        <Link to="/" className="navbar-link">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.94206 8.62125C7.95863 8.62125 7.9752 8.62125 7.99508 8.62125C8.0017 8.62125 8.00833 8.62125 8.01496 8.62125C8.0249 8.62125 8.03815 8.62125 8.04809 8.62125C9.01889 8.60468 9.80415 8.26341 10.384 7.61069C11.6596 6.17271 11.4475 3.7076 11.4244 3.47235C11.3415 1.70636 10.5066 0.861462 9.8174 0.467178C9.30383 0.172292 8.70412 0.0132533 8.03484 0H8.01164C8.00833 0 8.0017 0 7.99839 0H7.97851C7.61073 0 6.88843 0.0596397 6.19595 0.453924C5.50015 0.848209 4.65194 1.6931 4.56911 3.47235C4.54591 3.7076 4.33386 6.17271 5.60949 7.61069C6.18601 8.26341 6.97126 8.60468 7.94206 8.62125ZM5.45376 3.55519C5.45376 3.54525 5.45708 3.53531 5.45708 3.52868C5.56642 1.15303 7.25289 0.897909 7.9752 0.897909H7.98845C7.99508 0.897909 8.00502 0.897909 8.01496 0.897909C8.90955 0.917788 10.4304 1.28225 10.5331 3.52868C10.5331 3.53862 10.5331 3.54856 10.5364 3.55519C10.5397 3.57838 10.7716 5.83144 9.718 7.0176C9.30052 7.48809 8.74389 7.72003 8.01164 7.72665C8.00502 7.72665 8.0017 7.72665 7.99508 7.72665C7.98845 7.72665 7.98514 7.72665 7.97851 7.72665C7.24958 7.72003 6.68963 7.48809 6.27546 7.0176C5.22514 5.83806 5.45045 3.57507 5.45376 3.55519Z" fill="#023915"/>
                                <path d="M14.804 12.7099C14.804 12.7066 14.804 12.7033 14.804 12.6999C14.804 12.6734 14.8007 12.6469 14.8007 12.6171C14.7808 11.9611 14.7377 10.427 13.2998 9.93663C13.2898 9.93332 13.2766 9.93 13.2666 9.92669C11.7723 9.54566 10.5298 8.68419 10.5166 8.67425C10.3145 8.53178 10.0361 8.58148 9.89367 8.78359C9.7512 8.98571 9.80089 9.26402 10.003 9.4065C10.0593 9.44626 11.378 10.364 13.0281 10.7882C13.8001 11.0632 13.8862 11.8882 13.9094 12.6436C13.9094 12.6734 13.9094 12.6999 13.9127 12.7264C13.916 13.0246 13.8962 13.4852 13.8431 13.7503C13.3064 14.0551 11.2024 15.1087 8.00176 15.1087C4.81436 15.1087 2.69715 14.0518 2.15708 13.747C2.10406 13.4819 2.08087 13.0213 2.0875 12.7231C2.0875 12.6966 2.09081 12.6701 2.09081 12.6403C2.114 11.8849 2.20015 11.0598 2.97215 10.7848C4.62218 10.3607 5.94088 9.43963 5.99721 9.40318C6.19932 9.26071 6.24902 8.98239 6.10655 8.78028C5.96408 8.57817 5.68576 8.52847 5.48364 8.67094C5.47039 8.68088 4.23452 9.54234 2.73359 9.92338C2.72034 9.92669 2.7104 9.93 2.70046 9.93332C1.26248 10.427 1.21941 11.9611 1.19953 12.6138C1.19953 12.6436 1.19953 12.6701 1.19621 12.6966C1.19621 12.6999 1.19621 12.7033 1.19621 12.7066C1.1929 12.8789 1.18959 13.7635 1.36519 14.2075C1.39833 14.2936 1.45797 14.3665 1.53749 14.4162C1.63689 14.4825 4.01916 16 8.00508 16C11.991 16 14.3733 14.4792 14.4727 14.4162C14.5489 14.3665 14.6118 14.2936 14.645 14.2075C14.8106 13.7668 14.8073 12.8822 14.804 12.7099Z" fill="#023915"/>
                            </svg>                            
                        </Link>
                        <div className="navbar-dropdown is-right">
                            <Link to="/" onClick={loginCheckprofile} className="navbar-item">
                                    Profile
                                </Link>
                            <Link to="/" onClick={loginCheckorder} className="navbar-item">
                                    Order
                                </Link>
                            <Link to="/" onClick={loginCheckwishlist} className="navbar-item">
                                    Wishlist
                                </Link>
                            <Link to="/" onClick={loginCheckaddress} className="navbar-item">
                                    Address book
                                </Link>
                            <Link to="/" onClick={logout} className="navbar-item">
                                    Logout
                                </Link>
                        </div>
                    </div> */}
                        {/*  <div className="navbar-item has-dropdown is-hoverable" *ngIf='!isLogged'>
                        <Link to="/" className="navbar-link" onClick={loginCheckprofile}>
                            <LazyLoadImage src="../assets/images/icons/avatar.svg" alt="" width="100%" height="100%" />
                        </Link>
                    </div> */}
                    </div>

                    {/*  onClick={openBurger} [class.is-active]="activeBurger" */}
                    <Link to="/" role="button" className={"navbar-burger burger " + (activeBurgerMenu ? 'is-active' : '')} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={openBurger}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </Link>
                    <a href={headerData.tel+ headerData.phone_no} className="navbar-item transparent-nav is-para para-medium header-call-btn">
                        <LazyLoadImage src={IMAGE_PATH+headerData.logo.call_icon} alt={headerData.logo.call_icon_alt_title} width="100%" height="100%" />
                        <span>{headerData.phone_no}</span>
                    </a>
            
                    <div className={"navbar-menu burger-navigation " + (activeBurgerMenu ? 'is-active' : '')}>
                        <div className="navbar-start content">
                            {/* <Link to={headerData.newsAndUpdateRedirect} onClick={openBurger} className="navbar-item is-blue heading-02">{headerData.newsAndUpdateHeading}</Link> */}
                            {/* Projects */}
                            <div className="tab-accordion full-width">
                                <h3 className="accordion-section-title" data-acc="menu_01">
                                    <p className="navbar-item is-blue heading-02">{headerData.projectHeading}</p>
                                    <span></span>
                                </h3>
                                <div className="accordion-section-content" id="menu_01" style={{ display: "none" }}>
                                    {headerData.project.map((projectRow, i) => (
                                        <Link className="navbar-item is-para is-black" to={headerData.projectRedirect+"/" + projectRow.name.replace(/\s+/g, '-').toLowerCase()} key={i} onClick={openBurger}>{projectRow.name}</Link>
                                    ))}
                                    {/* <Link className="navbar-item is-para is-black" to="/product/hot-tubs" onClick={openBurger}>Hot Tubs</Link>
                                    <Link className="navbar-item is-para is-black" to="/product/heat-pumps" onClick={openBurger}>Heat Pumps</Link> */}
                                </div>
                            </div>
                            {/* Product */}
                            <div className="tab-accordion full-width">
                                <h3 className="accordion-section-title" data-acc="menu_02">
                                    <p className="navbar-item is-blue heading-02">{headerData.productHeading}</p>
                                    <span></span>
                                </h3>
                                <div className="accordion-section-content" id="menu_02" style={{ display: "none" }}>
                                    {headerData.product.map((productRow, i) => (
                                        <Link className="navbar-item is-para is-black" to={headerData.productRedirect+"/" + productRow.name.replace(/\s+/g, '-').toLowerCase()} key={i} onClick={openBurger}>{productRow.name}</Link>
                                    ))}
                                    {/* <Link className="navbar-item is-para is-black" to="/product/hot-tubs" onClick={openBurger}>Hot Tubs</Link>
                                    <Link className="navbar-item is-para is-black" to="/product/heat-pumps" onClick={openBurger}>Heat Pumps</Link> */}
                                </div>
                            </div>
                            {/* Service */}
                            <div className="tab-accordion full-width">
                                <h3 className="accordion-section-title" data-acc="menu_03">
                                    <p className="navbar-item is-blue heading-02">{headerData.serviceHeading}</p>
                                    <span></span>
                                </h3>
                                <div className="accordion-section-content" id="menu_03" style={{ display: "none" }}>
                                    {headerData.service.map((serviceRow, i) => (
                                        <Link className="navbar-item is-para is-black" to={headerData.serviceRedirect+"/" + serviceRow.listing_name.replace(/\s+/g, '-').toLowerCase()} key={i} onClick={openBurger}>{serviceRow.listing_name}</Link>
                                    ))}
                                </div>
                            </div>
                            {/* Renolit */}
                            <div className="tab-accordion full-width" style={{ display: 'none' }}>
                                <h3 className="accordion-section-title" data-acc="menu_03">
                                    <p className="navbar-item is-blue heading-02">{headerData.renolitHeading}</p>
                                    <span></span>
                                </h3>
                                <div className="accordion-section-content" id="menu_03" style={{ display: "none" }}>
                                    {headerData.renolit.map((renolitRow, i) => (
                                        <Link to={headerData.renolitRedirect+"/" + renolitRow.listing_name.replace(/\s+/g, '-').toLowerCase()} className="navbar-item is-para is-black" key={i} onClick={openBurger}>{renolitRow.listing_name}</Link>
                                    ))}
                                </div>
                            </div>
                            <Link to={headerData.whySwimwellRedirect} onClick={openBurger} className="navbar-item is-blue heading-02">{headerData.whySwimwellHeading}</Link>
                            <Link to={headerData.aboutRedirect} onClick={openBurger} className="navbar-item is-blue heading-02">{headerData.aboutHeading}</Link>
                            <Link to={headerData.contactRedirect} onClick={openBurger} className="navbar-item is-blue heading-02">{headerData.contactHeading}</Link>
                        </div>
                    </div>

                    {/*  [class.is-active]="activeSearchBar" *ngIf="removeHeader" */}
                    <div className="navbar-menu header-search-wrp" >
                        <form action="" >
                            <div className="field">
                                <p className="control">
                                    {/*  [(ngModel)]="searchKey" (keydown.enter)="search()"*/}
                                    <input type="text" name="searchKey" placeholder="Search rug by name, material, technique or style" />
                                </p>
                            </div>
                            {/*  onClick={search} onClick={openSearchBar} */}
                            {/* <Link to="" className="navbar-item" [class.is-loading]="submitBtnLoader" > */}
                            <Link to="/" className="navbar-item">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.8598 15.184L11.9716 11.2958C13.0146 10.0967 13.6461 8.53215 13.6461 6.82217C13.6461 3.05809 10.5844 0 6.82388 0C3.0598 0 0.00170898 3.06164 0.00170898 6.82217C0.00170898 10.5827 3.06335 13.6443 6.82388 13.6443C8.53386 13.6443 10.0984 13.0129 11.2975 11.9698L15.1857 15.8581C15.278 15.9503 15.4022 16 15.5228 16C15.6434 16 15.7676 15.9539 15.8598 15.8581C16.0443 15.6736 16.0443 15.3685 15.8598 15.184ZM0.956033 6.82217C0.956033 3.5867 3.58841 0.957871 6.82033 0.957871C10.0558 0.957871 12.6846 3.59024 12.6846 6.82217C12.6846 10.0541 10.0558 12.69 6.82033 12.69C3.58841 12.69 0.956033 10.0576 0.956033 6.82217Z" fill="#023915" />
                                </svg>
                            </Link>
                            {/*  onClick={clearSearch} */}
                            <Link to="/" className="navbar-item">
                                <button className="delete is-medium"></button>
                            </Link>
                        </form>
                    </div>
                </div>
            </nav>
            {/*  Navigation ends */}
        </>
    );
}

export default Header;