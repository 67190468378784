import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ADMIN_URL, CONFIG, IMAGE_PATH } from '../../../config/constant';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { ValidationForm, TextInputGroup } from 'react-bootstrap4-form-validation';
import WhatsAppIcon from '../../../assets/images/whatsapp-icon.webp';
import CallIcon from '../../../assets/images/call-icon.png';
import FacebookIcon from '../../../assets/images/facebook-icon.png';
import PinterestIcon from '../../../assets/images/pinterest-icon.png';
import LinkedInIcon from '../../../assets/images/linkedIn-icon.png';
import InstagramIcon from '../../../assets/images/instagram-icon.png';
import YoutubeIcon from '../../../assets/images/youtube-icon.png';
import brochure from '../../../assets/images/website-frontend/swimwell-brochure-2023.pdf';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {

    const [footerData, setFooterData] = useState({
        footerData: {},
        service: [],
        project: [],
        product: [],
        renolitHeading: "",
        renolitRedirect: "",
        serviceHeading: "",
        serviceRedirect: "",
        projectHeading: "",
        projectRedirect: "",
        productHeading: "",
        productRedirect: "",
        aboutHeading: "",
        aboutRedirect: "",
        blogHeading: "",
        blogRedirect: "",
        newsAndUpdateHeading: "",
        newsAndUpdateRedirect: "",
        contactHeading: "",
        contactRedirect: "",
        emailHeading: "",
        phoneHeading: "",
        addressHeading: "",
        careerHeading: "",
        careerRedirect: "",
        faqHeading: "",
        faqRedirect: "",
        disclaimerHeading: "",
        disclaimerRedirect: "",
        privacyPolicyHeading: "",
        privacyPolicyRedirect: "",
        termsAndConditionHeading: "",
        termsAndConditionRedirect: "",
        form:{}
    });

    const requestOptionsFooter = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    };

    const loadFooterData = async () => {
        const result = await fetch(ADMIN_URL + "api/website-frontend/setting", requestOptionsFooter);
        result.json().then((content) => {
            setFooterData(content.success.footer);
            setDocumentReaday(true);
        });
    }

    const [popupForm, setpopupForm] = useState(false);
    const activePopupForm = () => {
        console.log('click');
        setpopupForm(!popupForm);
    }
    
    /** enquiry form start */
        const [formData, setFormData] = useState({
            list_key: CONFIG.helloLeads.list_key,
            first_name: "",
            last_name: "",
            company: "",
            designation: "",
            email: "",
            mobile: "",
            phone: "",
            fax: "",
            website: "",
            address_line1: "",
            address_line2: "",
            city: "",
            state: "",
            country: "",
            postal_code: "",
            notes: "",
            interests: "",
            category: "",
            tags: "",
            mobile_code: "",
            deal_size:"",
            potential:""
        });

        //for managewell CRM
        // const [formData, setFormData] = useState({
        //     name: "",
        //     email: "",
        //     phone_no: "",
        //     interested_in: "",
        //     message: "",
        // });
                
        const navigate = useHistory();

        const onSubmit = async (e) => {
            e.preventDefault();
            let requestOptionsFormAdd = {
                method: 'POST',
                headers: { 
                    'Xemail': CONFIG.helloLeads.Xemail,
                    'hls-key': CONFIG.helloLeads.hlskey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData })
            };
            let formAddResponse = await fetch("https://app.helloleads.io/index.php/private/api/leads", requestOptionsFormAdd
            );
            console.log(formAddResponse)
            if (formAddResponse.status == 201) {
                // await successPNotify();
                navigate.push('/thank-you');
            }

            // for managewell CRM
            // let requestOptionsFormAdd = {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` },
            //     body: JSON.stringify({ ...formData })
            // };
            // let formAddResponse = await fetch(ADMIN_URL + "api/website-admin/enquiry/add", requestOptionsFormAdd
            // );
            // if (formAddResponse.status == 200) {
            //     // await successPNotify();
            //     navigate.push('/thank-you');
            // }

            activePopupForm();

            setFormData({
                list_key: CONFIG.helloLeads.list_key,
                first_name: "",
                last_name: "",
                company: "",
                designation: "",
                email: "",
                mobile: "",
                phone: "",
                fax: "",
                website: "",
                address_line1: "",
                address_line2: "",
                city: "",
                state: "",
                country: "",
                postal_code: "",
                notes: "",
                interests: "",
                category: "",
                tags: "",
                mobile_code: "",
                deal_size:"",
                potential:""
            });

            // setFormData({
            //     name: "",
            //     email: "",
            //     phone_no: "",
            //     interested_in: "",
            //     message: "",
            // });
        };

        const onInputChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };
    /** enquiry form end */

    const [documentReaday, setDocumentReaday] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(function() {
            loadFooterData();
        }, 3000)
    }, [])


    return (
        <>
        {documentReaday ? 
        <>
            {/* MAKE AN ENQUIRY start */}
                <section className="make-enquiry top-bottom-padding" onClick={activePopupForm}>
                    <marquee behavior="alternate" direction="left">
                        <h2>{footerData.form.makeAnEnquiry} &nbsp;&nbsp;&nbsp; {footerData.form.makeAnEnquiry} &nbsp;&nbsp;&nbsp; {footerData.form.makeAnEnquiry} &nbsp;&nbsp;&nbsp; {footerData.form.makeAnEnquiry} &nbsp;&nbsp;&nbsp; {footerData.form.makeAnEnquiry}</h2>
                    </marquee>
                    <p className="btn-big-cricle-wrp blue-bg">
                        <span className="right-arrow white"></span>
                    </p>
                </section>

                {/* product enquire now form */}
                    <section className={"section popup-up " + (popupForm ? 'active' : '')}>
                        <div className="container">
                            <div className="has-background-white">
                                <span className="close-btn" onClick={activePopupForm}>+</span>
                                <h3 className="heading-02 form-heading mb-5">{footerData.form.enquireNow}</h3>
                                
                                <ValidationForm onSubmit={(e) => onSubmit(e)}>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <div className="column is-full" style={{padding: '0'}}>
                                                <div className="columns is-multiline">
                                                    <div className="column is-6">
                                                        {/* <label className="label has-text-weight-normal">Full Name</label> */}
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control is-expanded">
                                                                    <TextInputGroup className="input border-bottom gray" name='first_name' id='first_name' type="text" placeholder={footerData.form.name} required value={formData.first_name} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{ type:"Please enter a name.",}} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <ng-container *ngIf="validationArray.email.req || validationArray.email.em">
                                                            <p className="help is-danger" *ngIf="validationArray.email.req">productSingleCategory.enquireNowFormContent.requiredField</p>
                                                            <p className="help is-danger" *ngIf="validationArray.email.em">productSingleCategory.enquireNowFormContent.invalidEmailID</p>
                                                        </ng-container> */}
                                                    </div>
                                                    <div className="column is-6">
                                                        {/* <label className="label has-text-weight-normal">Email ID</label> */}
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control is-expanded">
                                                                    <TextInputGroup className="input border-bottom gray" name='email' id='email' type="email" placeholder={footerData.form.email} required value={formData.email} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{ type:"Please enter a valid email.",}} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <ng-container *ngIf="validationArray.email.req || validationArray.email.em">
                                                            <p className="help is-danger" *ngIf="validationArray.email.req">productSingleCategory.enquireNowFormContent.requiredField</p>
                                                            <p className="help is-danger" *ngIf="validationArray.email.em">productSingleCategory.enquireNowFormContent.invalidEmailID</p>
                                                        </ng-container> */}
                                                    </div>
                                                    <div className="column is-6">
                                                        {/* <label className="label has-text-weight-normal">Mobile No.</label> */}
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control is-expanded">
                                                                    <TextInputGroup className="input border-bottom gray" name='mobile' id='mobile' type="number" placeholder={footerData.form.phone_no} required value={formData.mobile} onChange={(e) => onInputChange(e)} minLength="10" maxLength="10" autoComplete="off" errorMessage={{type: "Please enter a valid Phone Number.",minLength: "Please enter a 10 digit Phone Number.", maxLength: "Please enter a 10 digit Phone Number.",}} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <ng-container *ngIf="validationArray.phone.req">
                                                            <p className="help is-danger" *ngIf="validationArray.phone.req">productSingleCategory.enquireNowFormContent.requiredField</p>
                                                        </ng-container> */}
                                                    </div>
                                                    <div className="column is-6">
                                                        {/* <label className="label has-text-weight-normal">Interested In</label> */}
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control is-expanded">
                                                                    <span className='select full-width border-bottom gray'>
                                                                        <select name='interests' id='interests' value={formData.interests} className='full-width' required  onChange={(e) => onInputChange(e)}>
                                                                            <option value="Select">{footerData.form.select}</option>
                                                                            {footerData.service.map((serviceRow, i) => (
                                                                                <option value={serviceRow.listing_name.replace(/\s+/g, '-').toLowerCase()}>{serviceRow.listing_name}</option>
                                                                            ))}
                                                                            {/* <option value="swimming-pool-construction">Swimming Pool Construction</option>
                                                                            <option value="swimming-pool-renovation">Swimming Pool Renovation</option>
                                                                            <option value="swimming-pool-maintenance">Swimming Pool Maintenance</option>
                                                                            <option value="swimming-pool-products">Swimming Pool Products</option>
                                                                            <option value="other-related-services">Other Related Services</option> */}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <ng-container *ngIf="validationArray.companyAddress.req">
                                                            <p className="help is-danger" *ngIf="validationArray.companyAddress.req">productSingleCategory.enquireNowFormContent.requiredField</p>
                                                        </ng-container> */}
                                                    </div>
                                                    <div className="column is-12">
                                                        {/* <label className="label has-text-weight-normal">Message</label> */}
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control is-expanded">
                                                                    <TextInputGroup className="input border-bottom gray" name='notes' id='notes' type="text" placeholder={footerData.form.message} required value={formData.notes} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{ type:"Please enter a your query.",}} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <ng-container *ngIf="validationArray.enquiry.req">
                                                            <p className="help is-danger" *ngIf="validationArray.enquiry.req">productSingleCategory.enquireNowFormContent.requiredField</p>
                                                        </ng-container> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                            <div className="column is-full">
                                                <div className="field">
                                                    <button className="btn-big-cricle-wrp small-size blue">
                                                        <span className="text blue is-para">{footerData.form.submit}</span>
                                                    </button>
                                                </div>
                                            </div>
                                    </Form.Row>
                                </ValidationForm>
                            </div>
                        </div>
                    </section>
                {/* product enquire now */}
            {/* MAKE AN ENQUIRY ends */}


            {/* Find a dealer start */}
            <section className="find-dealer top-bottom-padding is-bg-dark-blue"  style={{ display: 'none' }}>
                <div className="container extrahd">
                    <h2 className="heading-01 is-white margin-b-1">{footerData.findDealerHeading}</h2>
                    <div className="columns">
                        <div className="column is-4">
                            <div className="field-body">
                                <div className="field">
                                    {/* <p className="control is-expanded">
                                    <input className="input border-input" type="text" placeholder="Country" formControlName="country" (focusout)="validateAddressForm('country')" />
                                </p> */}
                                    <div className="select is-primary height-100 full-width border-bottom is-primary big">
                                        <select className="full-width">
                                            <option value=''>Enter State Name</option>
                                            <option value=''>Enter State Name</option>
                                            <option value=''>Enter State Name</option>
                                        </select>
                                    </div>
                                    {/* <p className="help is-danger">This field is required.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="column is-4">
                            <div className="field-body">
                                <div className="field">
                                    {/* <p className="control is-expanded">
                                    <input className="input border-input" type="text" placeholder="Country" formControlName="country" (focusout)="validateAddressForm('country')" />
                                </p> */}
                                    <div className="select is-primary height-100 full-width border-bottom is-primary big">
                                        <select className="full-width">
                                            <option value=''>Enter State Name</option>
                                            <option value=''>Enter State Name</option>
                                            <option value=''>Enter State Name</option>
                                        </select>
                                    </div>
                                    {/* <p className="help is-danger">This field is required.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="column is-4">
                            <Link to="/" className="btn-big-cricle-wrp white">
                                <span className="right-arrow white"></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Find a dealer ends */}

            {/* Footer starts */}
            <footer className="footer hero">
                <div className="container fullhd full-width">
                    <div className="content">
                        <div className="columns">
                            <ul className="menu-list column ml-0">
                                <li>
                                    {/* brochure start */}
                                    <h4 className="menu-label">
                                        <a className="is-para" href={brochure} target={'_blank'}>{'Download a Brochure'}</a>
                                    </h4>
                                    {/* brochure end */}
                                    {/* <h4 className="menu-label">
                                        <Link className="is-para" to={'pool-types'}>{'Pool Types'}</Link>
                                    </h4> */}
                                    <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.renolitRedirect}>{footerData.renolitHeading}</Link>
                                    </h4>
                                    <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.aboutRedirect}>{footerData.aboutHeading}</Link>
                                    </h4>
                                    <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.blogRedirect}>{footerData.blogHeading}</Link>
                                    </h4>
                                    {/* <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.newsAndUpdateRedirect}>{footerData.newsAndUpdateHeading}</Link>
                                    </h4> */}
                                    <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.careerRedirect}>{footerData.careerHeading}</Link>
                                    </h4>
                                    {/* <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.faqRedirect}>{footerData.faqHeading}</Link>
                                    </h4> */}
                                    <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.whySwimwellRedirect}>{footerData.whySwimwellHeading}</Link>
                                    </h4>
                                </li>
                            </ul>
                            <ul className="menu-list column">
                                <li>
                                    <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.serviceRedirect}>{footerData.serviceHeading}</Link>
                                    </h4>
                                    {footerData.service.map((serviceRow, i) => (
                                        <Link className="" to={footerData.serviceRedirect+"/" + serviceRow.listing_name.replace(/\s+/g, '-').toLowerCase()}>{serviceRow.listing_name}</Link>
                                    ))}
                                </li>
                            </ul>
                            {/* style={{display: 'none'}} */}
                            <ul className="menu-list column">
                                <li>
                                    <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.projectRedirect}>{footerData.projectHeading}</Link>
                                    </h4>
                                    {footerData.project.map((projectRow, i) => (
                                        <Link className="" to={footerData.projectRedirect+"/" + projectRow.name.replace(/\s+/g, '-').toLowerCase()}>{projectRow.name}</Link>
                                    ))}
                                    {/* <Link className="" to="/project/commercial" >Commercial</Link>
                                    <Link className="" to="/project/competition" >Competition</Link>
                                    <Link className="" to="/project/personal" >Personal</Link>
                                    <Link className="" to="/project/hotel-and-resorts" >Hotel And Resorts</Link>
                                    <Link className="" to="/project/water-parks" >Water Parks</Link> */}
                                </li>
                            </ul>
                            <ul className="menu-list column">
                                <li>
                                    <h4 className="menu-label">
                                        <Link className="is-para" to={footerData.productRedirect}>{footerData.productHeading}</Link>
                                    </h4>
                                    {footerData.product.map((productRow, i) => (
                                        <Link className="" to={footerData.productRedirect+"/" + productRow.name.replace(/\s+/g, '-').toLowerCase()}>{productRow.name}</Link>
                                    ))}
                                    {/* <Link className="" to="/products/hot-tubs">Hot Tubs</Link> */}
                                </li>
                            </ul>
                            <ul className="menu-list column">
                                <li>
                                    <h4 className="menu-label">
                                        <Link className="is-para pl-0" to={footerData.contactRedirect}>{footerData.contactHeading}</Link>
                                    </h4>
                                    <p className="pb-2">
                                        <span><b>Contact Details</b></span>
                                        <br />
                                        <a href={footerData.tel + footerData.plus91+ footerData.footerData.phone_1} className="py-0 pl-0"> {footerData.plus91+footerData.footerData.phone_1}</a>
                                        <a href={footerData.tel + footerData.plus91+ footerData.footerData.phone_2} className="py-0 pl-0"> {footerData.plus91+footerData.footerData.phone_2}</a>
                                        <a href={footerData.mailto + footerData.footerData.email_id_2} className="py-0 pl-0">{footerData.footerData.email_id_2}</a>
                                    </p>
                                    <p className="pb-2">
                                        <span><b>For International Clients</b></span>
                                        <a href={footerData.mailto + footerData.footerData.email_id_1} className="py-0 pl-0">{footerData.footerData.email_id_1}</a>
                                    </p>
                                    <p className="pb-2">
                                        <span><b>{footerData.addressHeading}</b></span>
                                        <br />
                                        {footerData.footerData.address}
                                    </p>
                                    <div className='social-wrp'>
                                        <a href={footerData.facebookRedirect} target="_blank">
                                            <LazyLoadImage src={IMAGE_PATH+footerData.facebookIcon} alt={footerData.facebookIconAltTitle} />
                                        </a>
                                        <a href={footerData.linkedinRedirect} target="_blank">
                                            <LazyLoadImage src={IMAGE_PATH+footerData.linkedinIcon} alt={footerData.linkedinIconAltTitle} />
                                        </a>
                                        <a href={footerData.instagramRedirect} target="_blank">
                                            <LazyLoadImage src={IMAGE_PATH+footerData.instagramIcon} alt={footerData.instagramIconAltTitle} />
                                        </a>
                                        <a href={footerData.pinterestRedirect} target="_blank">
                                            <LazyLoadImage src={IMAGE_PATH+footerData.pinterestIcon} alt={footerData.pinterestIconAltTitle} />
                                        </a>
                                        <a href={footerData.youtubeRedirect} target="_blank">
                                            <LazyLoadImage src={IMAGE_PATH+footerData.youtubeIcon} alt={footerData.youtubeIconAltTitle} />
                                        </a>
                                    </div>
                                    <div className="social-share">
                                        {/* <p shareButton="whatsapp">
                                <LazyLoadImage src="../../../assets/images/whatsApp.png" alt="" />
                            </p> */}
                                        {/* <Link to="{{app.footer.footerDynamic.facebook_link}}" target="_blank" className="pl-0">
                                <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.facebook_image}}" alt="{{app.footer.footerDynamic.facebook_image_alt_title}}" />
                            </Link>
                            <Link to="{{app.footer.footerDynamic.twitter_link}}" target="_blank">
                                <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.twitter_image}}" alt="{{app.footer.footerDynamic.twitter_image_alt_title}}" />
                            </Link>
                            <Link to="{{app.footer.footerDynamic.linkedin_link}}" target="_blank">
                                <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.linkedin_image}}" alt="{{app.footer.footerDynamic.linkedin_image_alt_title}}" />
                            </Link> */}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <hr />
                        <div className="columns copyright">
                            <div className="column">
                                <Link to={footerData.disclaimerRedirect} className="is-para para-basic">{footerData.disclaimerHeading}</Link>
                                <Link to={footerData.privacyPolicyRedirect} className="is-para para-basic">{footerData.privacyPolicyHeading}</Link>
                                <Link to={footerData.termsAndConditionRedirect} className="is-para para-basic">{footerData.termsAndConditionHeading}</Link>
                            </div>
                            <div className="column">
                                <p className="is-para para-basic has-text-right">{footerData.footerData.copyright}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer ends */}


            <a href={footerData.tel + footerData.plus91+ footerData.footerData.phone_1} className="footer-call-btn whatsapp">
                <span>
                    <LazyLoadImage src={IMAGE_PATH+footerData.footerData.call_icon} alt={footerData.footerData.call_icon_alt_title} />
                </span>
            </a>

            <a href={"https://api.whatsapp.com/send?phone="+footerData.footerData.whatsapp_no+"&text="+footerData.footerData.whatsapp_text} target="_blank" className="whatsapp">
                <LazyLoadImage src={IMAGE_PATH+footerData.footerData.whatsapp_icon} alt={footerData.footerData.whatsapp_icon_alt_title} title={footerData.footerData.whatsapp_icon_alt_title} width="100%" height="100%" />
            </a>
        </>
        : ""}
        </>
    );
}

export default Footer;